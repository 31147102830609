<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!reseller">
      <span class="loader"></span>
    </span>
    <div v-if="reseller" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <reseller-view-global
            :reseller="reseller"
            @onGenerateToken="generateToken"
          />
        </tab-pane>

        <tab-pane title="organizations" id="2">
          <span slot="title">
            <i class="ni ni-building"></i>
            {{ $t("COMMON.ORGANIZATIONS") }}
          </span>
          <reseller-view-organizations :reseller="reseller" />
        </tab-pane>

        <tab-pane title="users" id="3">
          <span slot="title">
            <i class="ni ni-single-02"></i>
            {{ $t("COMMON.USERS") }}
          </span>
          <reseller-view-users :reseller="reseller" />
        </tab-pane>

        <tab-pane title="roles" id="4">
          <span slot="title">
            <i class="ni ni-hat-3"></i>
            {{ $t("COMMON.ROLES") }}
          </span>
          <reseller-view-roles :reseller="reseller" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <reseller-view-logs :reseller="reseller" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ResellerViewGlobal from "../partials/ResellerViewGlobal.vue";
import ResellerViewOrganizations from "../partials/ResellerViewOrganizations.vue";
import ResellerViewUsers from "../partials/ResellerViewUsers.vue";
import ResellerViewRoles from "../partials/ResellerViewRoles.vue";
import ResellerViewLogs from "../partials/ResellerViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ResellerViewGlobal,
    ResellerViewOrganizations,
    ResellerViewUsers,
    ResellerViewRoles,
    ResellerViewLogs,
  },

  mixins: [requestErrorMixin],

  props: {
    resellerId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      reseller: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("resellers/get", this.resellerId);
        this.reseller = this.$store.getters["resellers/reseller"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async generateToken() {
      const confirmation = await swal.fire({
        text: this.$t("COMMON.GENERATE_ACCESS_TOKEN_CONFIRMATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.value !== true) {
        return;
      }

      try {
        this.loading = true;

        const resp = await this.$store.dispatch(
          "resellers/generateAccessToken",
          this.reseller.id
        );

        swal.fire({
          text: resp.access_token,
          customClass: {
            popup: "delete-popup",
          },
          type: "question",
          buttonsStyling: false,
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
        });

        this.$notify({
          type: "success",
          message: this.$t("COMMON.ACCESS_TOKEN_GENERATED"),
        });

        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },
};
</script>
